import React, { useEffect } from 'react';

const Demo = () => {
  useEffect(() => {
    window.location.assign('https://youtu.be/52Xu6-JoDy0');
  }, []);

  return <div />;
};

export default Demo;
